<template>
  <section>
    <title-section
      title="Publicaciones"
    >
      <div class="level-item">
        <router-link class="button button_micro_dark_outlined" :to="{ name: 'categorias' }">
          CATEGORÍAS
        </router-link>
      </div>
      <div class="level-item">
        <router-link class="button button_micro_dark" :to="{ name: 'agregar-publicacion' }">
          AGREGAR PUBLICACIÓN
        </router-link>
      </div>
    </title-section>
    <main-publications />
  </section>
</template>

<script>
export default {
  name: 'Publications',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    MainPublications: () => import('@/components/Publications/MainPublications.vue')
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font-size: 15px;
    width: 200px;
  }
  .button_micro_dark_outlined {
    border: solid 2px #262626;
  }
</style>
